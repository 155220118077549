import Instalaciones from "./paginas/Instalaciones";
import Inicio from "./paginas/Inicio";
import Horarios from "./paginas/Horarios";
import Contacto from "./paginas/Contacto";
const routes = {
  "/inicio": () => <Inicio />,
  "/instalaciones": () => <Instalaciones />,
  "/horarios": () => <Horarios />,
  "/contacto": () => <Contacto />,
};

export default routes;
