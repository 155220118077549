import Carousel from "react-bootstrap/Carousel";
import habitacion from "../habitacion.jpeg";
import habitacion3 from "../habitacion3.jpeg";
import "./Paginas.css";

function Instalaciones() {
  return (
    <div>
      <div className="Titulo">
        <br></br>
        <h3 align="left">Habitaciones</h3>
      </div>
      <div>
        <Carousel variant="dark">
          <Carousel.Item>
            <img src={habitacion} alt="First slide" className="Carusel" />
            <Carousel.Caption>{/* etiquetas acá */}</Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="Carusel" src={habitacion3} alt="Second slide" />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}

export default Instalaciones;
