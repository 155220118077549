import "./Paginas.css";

function Inicio() {
  return (
    <div>
      <div className="Titulo">
        <br></br>
        <h2 align="left">Inicio</h2>
        <p align="left">Página en construccion</p>
      </div>
    </div>
  );
}

export default Inicio;
