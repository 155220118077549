import "./App.css";
import "./Navegacion";
import Navegacion from "./Navegacion";
import { useEffect } from "react";
import { useRoutes } from "@patched/hookrouter";
import routes from "./router";

function App() {
  const routeResult = useRoutes(routes);

  useEffect(() => {
    document.body.style.backgroundColor = "rgb(167, 213, 242)";
  }, []);

  return (
    <div className="App">
      <Navegacion></Navegacion>

      {routeResult}
    </div>
  );
}

export default App;
