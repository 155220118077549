import "./Paginas.css";

function Contacto() {
  return (
    <div>
      <div className="Titulo">
        <br></br>
        <h3 align="left">Contacto</h3>
        <br></br>
        <p align="left">3286-2003</p>
        <p align="left">77283714 Ext. 110</p>
        <p align="left">Doctor Jahir Quiroa</p>
      </div>
    </div>
  );
}

export default Contacto;
