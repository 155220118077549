import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { A } from "@patched/hookrouter";
import "./Navegacion.css";

function Navegacion() {
  return (
    <div>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand>
            <A href="/inicio">Sanatorio Familiar</A>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown title="Servicios" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">
                  Endoscopia
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Colonoscopia
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">ERCP</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">Consulta</NavDropdown.Item>
              </NavDropdown>

              <Nav.Link>
                <A href="/instalaciones">Instalaciones</A>
              </Nav.Link>

              <Nav.Link>
                <A href="/horarios">Horarios</A>
              </Nav.Link>

              <Nav.Link>
                <A href="/contacto">Contacto</A>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Navegacion;
