import "./Paginas.css";

function Horarios() {
  return (
    <div>
      <div className="Titulo">
        <br></br>
        <h3 align="left">Horarios</h3>
      </div>
    </div>
  );
}

export default Horarios;
